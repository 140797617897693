<template>
  <v-snackbar
    v-model="show"
    :multi-line="multiLine"
    :timeout="timeout"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        :color="colorBtn"
        text
        v-bind="attrs"
        @click="show = false"
      >
        {{ textBtn }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
  export default {
    data: () => ({
      multiLine: true,
      timeout: 6000,
      show: false,
      text: '',
      colorBtn: 'red',
      textBtn: 'Cerrar',
    }),
    methods: {
      setNew(show, text, textBtn, colorBtn){
        this.show = show;
        this.text = text;
        this.textBtn = textBtn;
        this.colorBtn = colorBtn;
      }
    }
  }
</script>
<template>
  <div>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="50%">
        <v-card>
          <v-card-title>{{ title }}</v-card-title>
          <v-card-text>
            <div v-if="show=='newDoc'">
              <v-form ref="formSesionNewDoc">
                <v-container>
                  <v-layout>
                    <v-flex xs12>
                      <v-file-input outlined @change="copyName" v-model="fileUploaded" accept="image/*,audio/*,video/*,text/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx" label="Haz clic para seleccionar el fichero a subir"></v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-if="show=='sendDoc'">
              <v-form ref="formSesionSendDoc">
                <v-container>
                  <v-layout>
                    <v-flex xs12>
                      <v-select
                        label="Asociar a productores"
                        outlined
                        v-model="selectClient"
                        :items="this.$store.state.productor"
                        item-text="nombre"
                        item-value="id"
                        multiple
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show=='cargaCSV'">
              <v-form ref="formSesionCargaCSV">
                <v-container>
                  <v-layout>
                    <v-flex xs12>
                      <v-file-input outlined v-model="fileUploadedCSV" accept=".csv" label="Haz clic para seleccionar el fichero a subir"></v-file-input>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-switch
                        v-model="switchCreaCliente"
                        label="Crear productor en caso de que no exista"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show=='newLand'">
              <v-form ref="formNewLand">
                <v-container>
                  <v-layout>
                    <v-flex xs4>
                      <v-text-field 
                        label="Nombre de la finca"
                        v-model="nombreFinca"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field 
                        label="Identificador"
                        v-model="identificador"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Superficie (Ha)"
                        v-model="superficie"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2>
                      <v-text-field 
                        label="Plantas"
                        v-model="plantas"
                        required
                        outlined
                        type="number"
                        min=0
                        step=1
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Variedad"
                        v-model="variedad"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="Polígono"
                        v-model="poligono"
                        required
                        outlined
                        type="number"
                        min=0
                        step=1
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="Parcela"
                        v-model="parcela"
                        required
                        outlined
                        type="number"
                        min=0
                        step=1
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="Recinto"
                        v-model="recinto"
                        required
                        outlined
                        type="number"
                        min=0
                        step=1
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-select 
                        label="Provincia"
                        v-model="provincia"
                        :items="this.$store.state.provincias"
                        item-text="nm"
                        item-value="id"
                        required
                        outlined
                        @change="loadMunicipios"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-select 
                        label="Localidad"
                        v-model="localidad"
                        :items="municipios"
                        item-text="nm"
                        item-value="nm"
                        required
                        outlined
                        :disabled="provincia == null"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show=='editLand'">
              <v-form ref="formEditLand">
                <v-container>
                  <v-layout>
                    <v-flex xs4>
                      <v-text-field 
                        label="Nombre de la finca"
                        v-model="nombreFincaEditar"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field 
                        label="Identificador"
                        v-model="identificadorEditar"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Superficie (Ha)"
                        v-model="superficieEditar"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2>
                      <v-text-field 
                        label="Plantas"
                        v-model="plantasEditar"
                        required
                        outlined
                        type="number"
                        min=0
                        step=1
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Variedad"
                        v-model="variedadEditar"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="Polígono"
                        v-model="poligonoEditar"
                        required
                        outlined
                        type="number"
                        min=0
                        step=1
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="Parcela"
                        v-model="parcelaEditar"
                        required
                        outlined
                        type="number"
                        min=0
                        step=1
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="Recinto"
                        v-model="recintoEditar"
                        required
                        outlined
                        type="number"
                        min=0
                        step=1
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-select 
                        label="Provincia"
                        v-model="provinciaEditar"
                        :items="this.$store.state.provincias"
                        item-text="nm"
                        item-value="id"
                        required
                        outlined
                        @change="loadMunicipios"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-select 
                        label="Localidad"
                        v-model="localidadEditar"
                        :items="municipios"
                        item-text="nm"
                        item-value="nm"
                        required
                        outlined
                        :disabled="provinciaEditar == null"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show=='editInput'">
              <v-form ref="formEditInput">
                <v-container>
                  <v-layout>
                    <v-flex xs3>
                      <v-menu
                        v-model="menuDateEntrada"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            autocomplete="off"
                            v-model="dateFormattedEntrada"
                            label="Fecha de entrada"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('fechaEntrada');"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="fechaEntrada"
                          outlined
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Kilos"
                        v-model="kilosEntrada"
                        required
                        outlined
                        min="0"
                        step="0.5"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Rendimiento"
                        v-model="rendimientoEntrada"
                        required
                        outlined
                        min="0"
                        step="0.5"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Acidez / Humedad"
                        v-model="acidezEntrada"
                        required
                        outlined
                        min="0"
                        step="0.5"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-text-field 
                        label="Descuento"
                        v-model="descuentoEntrada"
                        required
                        outlined
                        min="0"
                        step="0.5"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Albaran"
                        v-model="albaranEntrada"
                        required
                        outlined
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-text-field 
                        label="Comentario"
                        v-model="comentarioEntrada"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show=='editProduct'">
              <v-form ref="formEditProduct">
                <v-container>
                  <v-layout>
                    <v-flex xs3>
                      <v-select
                        v-model="tipoEditarProducto"
                        :items="productos"
                        label="Tipo de producto"
                        required
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Nombre del producto"
                        v-model="nombreEditarProducto"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field 
                        label="Categoría del producto"
                        v-model="categoriaEditarProducto"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show=='editLiquidation'">
              <v-form ref="formEditLiquidation">
                <v-container>
                  <v-layout>
                    <v-flex xs4>
                      <v-text-field
                        v-model="productoEditarLiquidacion"
                        label="Tipo de producto"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs5>
                      <v-text-field
                        v-model="categoriaEditarLiquidacion"
                        label="Categoría"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="campanaEditarLiquidacion"
                        label="Campaña"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2>
                      <v-text-field 
                        label="Kilos"
                        v-model="kilosEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="Precio"
                        v-model="precioEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="Escandallo"
                        v-model="escandalloEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-select
                        label=""
                        v-model="tipoescandalloEditarLiquidacion"
                        outlined
                        :items="tiposescandallo"
                        item-text="name"
                        item-value="value"
                        @change="calculaTotalEditarLiquidacion"
                      ></v-select>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="IVA"
                        v-model="ivaEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="IRPF"
                        v-model="irpfEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs4>
                      <v-text-field
                        v-model="totalEditarLiquidacion"
                        label="Total"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show=='editPrice'">
              <v-form ref="formEditPrice">
                <v-container>
                  <v-layout>
                    <v-flex xs3>
                      <v-text-field
                        v-model="productoEditarPrecio"
                        label="Tipo de producto"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="categoriaEditarPrecio"
                        label="Categoría"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        v-model="campanaEditarPrecio"
                        label="Campaña"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2>
                      <v-text-field 
                        label="Escandallo"
                        v-model="escandalloEditarPrecio"
                        type="number"
                        min="0"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field 
                        label="Precio"
                        v-model="precioEditarPrecio"
                        type="number"
                        min="0"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        v-model="estadoEditarPrecio"
                        :items="[{name: 'Activado', value: 1}, {name: 'Desactivado', value: 0}]"
                        item-text="name"
                        item-value="value"
                        label="Estado"
                        required
                        outlined
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show=='preFormatted'">
              <pre>{{ text }}</pre>
            </div>
            <div v-else>{{ text }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click.native="cancel">Cancelar</v-btn>
            <v-btn color="green" text @click.native="agree">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <showMessage ref="snackbar" />
  </div>
</template>
<script>
  import * as basics from '../components/basics.js'
  import showMessage from '../components/Messages.vue'
  export default {
    data () {
      return {
        dialog: false,
        title: '',
        text: '',
        show: 0,
        resolve: null,
        reject: null,

        selectClient: null,
        nombreNewDoc: '',
        fileUploaded: undefined,
        fileUploadedCSV: undefined,
        switchCreaCliente: false,
        identificador: '',
        nombreFinca: '',
        superficie: '',
        plantas: '',
        variedad: '',
        poligono: '',
        parcela: '',
        recinto: '',
        localidad: null,
        provincia: null,
        identificadorEditar: '',
        nombreFincaEditar: '',
        superficieEditar: '',
        plantasEditar: '',
        variedadEditar: '',
        poligonoEditar: '',
        parcelaEditar: '',
        recintoEditar: '',
        localidadEditar: null,
        provinciaEditar: null,
        municipios: [],
        productos: ['Frutos secos', 'Frutales'],
        tipoEditarProducto: null,
        nombreEditarProducto: '',
        categoriaEditarProducto: '',
        productoEditarPrecio: '',
        categoriaEditarPrecio: '',
        campanaEditarPrecio: '',
        escandalloEditarPrecio: '',
        precioEditarPrecio: '',
        estadoEditarPrecio: 1,
        menuDateEntrada: '',
        dateFormattedEntrada: '',
        fechaEntrada: '',
        kilosEntrada: '',
        rendimientoEntrada: '',
        acidezEntrada: '',
        albaranEntrada: '',
        comentarioEntrada: '',
        descuentoEntrada: '',
        productoEditarLiquidacion: '',
        categoriaEditarLiquidacion: '',
        campanaEditarLiquidacion: '',
        kilosEditarLiquidacion: '',
        precioEditarLiquidacion: '',
        escandalloEditarLiquidacion: '',
        tipoescandalloEditarLiquidacion: 'euro',
        tiposescandallo: [{name:'€', value:'euro'},{name:'%', value:'porcentaje'}],
        ivaEditarLiquidacion: '',
        irpfEditarLiquidacion: '',
        totalEditarLiquidacion: '',
      }
    },
    components: {
      showMessage
    },
    mounted(){
      this.$refs.formSesionNewDoc?.reset();
      this.$refs.formSesionSendDoc?.reset();
      this.$refs.formSesionCargaCSV?.reset();
      this.$refs.formEditProduct?.reset();
      this.$refs.formEditPrice?.reset();
      this.$refs.formNewLand?.reset();
      this.$refs.formEditLand?.reset();
      this.$refs.formEditInput?.reset();
      this.$refs.formEditLiquidation?.reset();
    },
    methods: {
      setNew(title, text, show = 0, data){
        this.dialog = true;
        this.title = title;
        this.text = text;
        this.show = show;
        if(show=="editProduct"){
          this.tipoEditarProducto = data[0];
          this.nombreEditarProducto = data[1];
          this.categoriaEditarProducto = data[2];
        }
        if(show=="editPrice"){
          this.productoEditarPrecio = data[0];
          this.categoriaEditarPrecio = data[1];
          this.campanaEditarPrecio = data[2];
          this.escandalloEditarPrecio = data[3];
          this.precioEditarPrecio = data[4];
          this.estadoEditarPrecio = data[5];
        }
        if(show=="editLand"){
          this.identificadorEditar = data[0];
          this.nombreFincaEditar = data[1];
          this.superficieEditar = data[2];
          this.plantasEditar = data[3];
          this.variedadEditar = data[4];
          this.poligonoEditar = data[5];
          this.parcelaEditar = data[6];
          this.recintoEditar = data[7];
          this.provinciaEditar = data[8];
          this.municipios = JSON.parse(JSON.stringify(this.$store.getters.municipioByProvincia(data[8]))),
          setTimeout(this.localidadEditar = data[9], 1000);
        }
        if(show=="editInput"){
          this.dateFormattedEntrada = data.fecha;
          this.fechaEntrada = basics.formatDate(data.fecha,"yyyy-mm-dd","/");;
          this.kilosEntrada = data.kilos;
          this.rendimientoEntrada = data.rendimiento;
          this.descuentoEntrada = data.descuento;
          this.acidezEntrada = data.acidez;
          this.albaranEntrada = data.albaran;
          this.comentarioEntrada = data.comentario;
          this.changeDateFormatted('fechaEntrada');
        }
        if(show=="editLiquidation"){
          this.productoEditarLiquidacion = data.valorproducto;
          this.categoriaEditarLiquidacion = data.calidad;
          this.campanaEditarLiquidacion = data.campana;
          this.kilosEditarLiquidacion = data.kilos;
          this.precioEditarLiquidacion = data.precio;
          this.escandalloEditarLiquidacion = data.valorescandallo;
          this.tipoescandalloEditarLiquidacion = data.tipoescandallo;
          this.ivaEditarLiquidacion = data.iva;
          this.irpfEditarLiquidacion = data.irpf;
          this.totalEditarLiquidacion = data.total;
        }
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        switch (this.show) {
          case "newDoc":
            this.resolve({
              response: true, 
              file: this.fileUploaded, 
              name: this.nombreNewDoc,
              productor: this.selectClient
            });
            this.$refs.formSesionNewDoc.reset();
            break;

          case "sendDoc":
            this.resolve({
              response: true, 
              productor: this.selectClient
            });
            this.$refs.formSesionSendDoc.reset();
            break;

          case "cargaCSV":
            this.resolve({
              response: true, 
              file: this.fileUploadedCSV,
              crea: this.switchCreaCliente
            });
            this.$refs.formSesionCargaCSV.reset();
            break;

          case "newLand":
            this.resolve({
              response: true, 
              nombre: this.nombreFinca,
              identificador: this.identificador,
              superficie: this.superficie,
              plantas: this.plantas,
              variedad: this.variedad,
              poligono: this.poligono,
              parcela: this.parcela,
              recinto: this.recinto,
              localidad: this.localidad,
              provincia: this.provincia,
            });
            this.$refs.formNewLand.reset();
            break;

          case "editLand":
            this.resolve({
              response: true, 
              nombre: this.nombreFincaEditar,
              identificador: this.identificadorEditar,
              superficie: this.superficieEditar,
              plantas: this.plantasEditar,
              variedad: this.variedadEditar,
              poligono: this.poligonoEditar,
              parcela: this.parcelaEditar,
              recinto: this.recintoEditar,
              provincia: this.provinciaEditar,
              localidad: this.localidadEditar,
            });
            this.$refs.formEditLand.reset();
            break;

          case "editInput":
            this.resolve({
              response: true, 
              fecha: this.fechaEntrada,
              kilos: this.kilosEntrada,
              rendimiento: this.rendimientoEntrada,
              acidez: this.acidezEntrada,
              albaran: this.albaranEntrada,
              comentario: this.comentarioEntrada,
              descuento: this.descuentoEntrada,
            });
            this.$refs.formEditInput.reset();
            break;

          case "editProduct":
            this.resolve({
              response: true, 
              nombre: this.nombreEditarProducto,
              categoria: this.categoriaEditarProducto,
              producto: this.tipoEditarProducto,
            });
            this.$refs.formEditProduct.reset();
            break;
          
          case "editPrice":
            this.resolve({
              response: true, 
              escandallo: this.escandalloEditarPrecio,
              precio: this.precioEditarPrecio,
              estado: this.estadoEditarPrecio,
            });
            this.$refs.formEditPrice.reset();
            break;

          case "editLiquidation":
            this.resolve({
              response: true, 
              kilos: this.kilosEditarLiquidacion,
              precio: this.precioEditarLiquidacion,
              escandallo: this.escandalloEditarLiquidacion,
              tipoescandallo: this.tipoescandalloEditarLiquidacion,
              iva: this.ivaEditarLiquidacion,
              irpf: this.irpfEditarLiquidacion,
              total: this.totalEditarLiquidacion
            });
            this.$refs.formEditLiquidation.reset();
            break;

          default:
            this.resolve({response: true});
            break;
        }
        this.dialog = false;
      },
      cancel() {
        this.$refs.formSesionNewDoc?.reset();
        this.$refs.formSesionSendDoc?.reset();
        this.$refs.formSesionCargaCSV?.reset();
        this.$refs.formNewLand?.reset();
        this.$refs.formEditLand?.reset();
        this.$refs.formEditInput?.reset();
        this.$refs.formEditProduct?.reset();
        this.$refs.formEditPrice?.reset();
        this.$refs.formEditLiquidation?.reset();
        this.resolve(false);
        this.dialog = false;
      },
      copyName(){
        if(this.fileUploaded){ this.nombreNewDoc = this.fileUploaded.name; }
      },
      loadMunicipios(){
        this.municipios = [];
        if(this.provincia!=null){ 
          this.municipios = JSON.parse(JSON.stringify(this.$store.getters.municipioByProvincia(this.provincia)));  
        }
        if(this.provinciaEditar!=null){ 
          this.municipios = JSON.parse(JSON.stringify(this.$store.getters.municipioByProvincia(this.provinciaEditar)));  
        }
      },
      changeDateFormatted(field){
        switch(field){
          case "fechaEntrada":
            if(this.fechaEntrada){
              this.dateFormattedEntrada = basics.formatDate(this.fechaEntrada,"dd/mm/yyyy");
            }
            break;
        }
      },
      calculaTotalEditarLiquidacion(){
        this.totalEditarLiquidacion = this.kilosEditarLiquidacion * this.precioEditarLiquidacion;
        if(this.tipoescandalloEditarLiquidacion=="euro"){
          if(this.escandalloEditarLiquidacion!=""){
            this.totalEditarLiquidacion = parseFloat(this.totalEditarLiquidacion) - parseFloat(this.escandalloEditarLiquidacion);
          }
        }else{
          if(this.escandalloEditarLiquidacion!=""){
            this.totalEditarLiquidacion = this.totalEditarLiquidacion - ((this.totalEditarLiquidacion * parseFloat(this.escandalloEditarLiquidacion))/100);
          }
        }
        if(this.ivaEditarLiquidacion!=""){
          this.totalEditarLiquidacion = this.totalEditarLiquidacion + ((this.totalEditarLiquidacion * parseFloat(this.ivaEditarLiquidacion))/100);
        }
        if(this.irpfEditarLiquidacion!=""){
          this.totalEditarLiquidacion = this.totalEditarLiquidacion - ((this.totalEditarLiquidacion * parseFloat(this.irpfEditarLiquidacion))/100);
        }
        this.totalEditarLiquidacion = Math.round(this.totalEditarLiquidacion * 100) / 100
      },
    }
  }
</script>
<style scoped>
pre { white-space: pre-wrap; word-wrap: break-word; font-family: inherit; }
.v-input{
  margin-right: 1em;
}
.v-file-input {
  flex-direction: row-reverse;
}
</style>